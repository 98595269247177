module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"},{"name":"Zilla Slab","file":"https://fonts.googleapis.com/css?family=Zilla+Slab"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bar des Sablettes","short_name":"Bar des Sablettes","start_url":"/","lang":"fr","description":"Découvrez le charme du Bar des Sablettes et profitez de l'ambiance balnéaire, tout en dégustant des cocktails exquis. Vivez des moments inoubliables entre amis ou en famille dans ce havre de détente, où les saveurs authentiques du rhum et le cadre pittoresque s'unissent pour créer une expérience inégalable.","background_color":"#222221","theme_color":"#222221","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5da26ae3ca4cda096c86c7988315c746"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
